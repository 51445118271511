import React from 'react'
import './footer.css'
import logo from"../../assets/ms-icon-310x310.png"
export default function Footer() {
  return (
    <footer>
      <a href="#" className='footer_logo'><img src={logo} alt="" /></a>


      <ul className="permalinks">
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Expierence</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#cotact">Contact</a></li>
      </ul>

      <div className="copyright">Noel Miranda@CopyRight</div>
    </footer>
  )
}
