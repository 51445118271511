import React from 'react'
import { useState } from 'react'
import './nav.css'
import { AiFillHome, AiOutlineUser, AiOutlineMessage } from 'react-icons/ai'
import { BsBookHalf } from 'react-icons/bs'
import { RiServiceLine } from 'react-icons/ri'
import { SiAboutdotme, SiSkillshare } from 'react-icons/si'
import { MdContactPhone } from 'react-icons/md'

export default function Nav() {
  const [ activeNav, setActiveNav ] = useState('#')
  return (
    <nav>
      <a href="#" onClick={()=> setActiveNav('#header')} className={activeNav === '#' ? 'active' : ''}><AiFillHome/></a>
      <a href="#about" onClick={()=> setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''} ><SiAboutdotme/></a>
      <a href="#experience"  onClick={()=> setActiveNav('#experience')} className={activeNav === '#experience' ? 'active' : ''}><RiServiceLine/></a>
      <a href="#portfolio"  onClick={()=> setActiveNav('#portfolio')} className={activeNav === '#portfolio' ? 'active' : ''}><BsBookHalf/></a>
      <a href="#contact" onClick={()=> setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}><MdContactPhone/></a>
    </nav>
  )
}
