import React from 'react'
import './experience.css'
import {BsPatchCheckFill } from 'react-icons/bs'
import {SiFirebase, SiCss3, SiJavascript, SiBootstrap, SiReact, SiNodedotjs, SiMongodb, SiMysql, SiFlutter } from 'react-icons/si'
import {AiFillHtml5 } from 'react-icons/ai'
import {BsFillPatchCheckFill } from 'react-icons/bs'

export default function Experience() {
  return (
    <section id='experience'>
      <h5>What Skills I Have</h5>
      <h2>My Experience</h2>

      <div className="container experience_container">
      <div className="experience_frontend">
        <h3>Frontend Development</h3>
        <div className="experience_content">
         <article className="experience_details">
          <AiFillHtml5 className='experience_details-icon'/>
          <div>
          <h4>HTML</h4>
          <small className="text-light">
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          </small>
          </div>
        </article> 
         <article className="experience_details">
          <SiCss3 className='experience_details-icon'/>
          <div>
          <h4>CSS</h4>
          <small className="text-light">
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          </small>
          </div>
        </article> 
         <article className="experience_details">
          <SiJavascript className='experience_details-icon'/>
          <div>
          <h4>JavaScript</h4>
          <small className="text-light">
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          </small>
          </div>
        </article> 
         <article className="experience_details">
          <SiFlutter className='experience_details-icon'/>
          <div>
          <h4>Flutter</h4>
          <small className="text-light">
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          </small>
          </div>
        </article> 
         <article className="experience_details">
          <SiReact className='experience_details-icon'/>
          <div>
          <h4>React</h4>
          <small className="text-light">
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          </small>
          </div>
        </article> 
         <article className="experience_details">
          <SiNodedotjs className='experience_details-icon'/>
          <div>
          <h4>Node</h4>
          <small className="text-light">
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          </small>
          </div>
        </article> 
         <article className="experience_details">
          <SiMongodb className='experience_details-icon'/>
          <div>
          <h4>MongoDB</h4>
          <small className="text-light">
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          </small>
          </div>
        </article> 
         <article className="experience_details">
          <SiMysql className='experience_details-icon'/>
          <div>
          <h4>MySQL</h4>
          <small className="text-light">
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          </small>
          </div>
        </article> 
         
        </div>
      </div>
      <div className="experience_backend">


             {/* END FRONT END */}        
      
      
      <h3>Application Analyst in Web/iOS/Android using Google Firebase</h3>
        <div className="experience_content">
        <article className="experience_details">
          <SiFirebase className='experience_details-icon'/>
          <div>
          <h4>Realtime Database</h4>
          <small className="text-light">
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          </small>
          </div>
        </article> 
        <article className="experience_details">
        <SiFirebase className='experience_details-icon'/>
          <div>
          <h4>Cloud Functions</h4>
          <small className="text-light">
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          </small>
          </div>
        </article> 
        <article className="experience_details">
        <SiFirebase className='experience_details-icon'/>
          <div>
          <h4>Authentication</h4>
          <small className="text-light">
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          </small>
          </div>
        </article> 
        <article className="experience_details">
          <SiFirebase className='experience_details-icon'/>
          <div>
          <h4>Hosting</h4>
          <small className="text-light">
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          </small>
          </div>
        </article> 
        <article className="experience_details">
          <SiFirebase className='experience_details-icon'/>
          <div>
          <h4>Cloud Storage</h4>
          <small className="text-light">
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          </small>
          </div>
        </article> 
        <article className="experience_details">
          <SiFirebase className='experience_details-icon'/>
          <div>
          <h4>Cloud Firestore</h4>
          <small className="text-light">
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          <BsFillPatchCheckFill className='experience_details-icon-level'/>
          </small>
          </div>
        </article> 
        
        </div>
        
      </div>
      </div>
    </section>
  )
}
