import React from 'react';
import './contact.css'
import { AiOutlineMail, AiFillLinkedin, AiOutlineWhatsApp } from 'react-icons/ai'
import emailjs from 'emailjs-com'
import { useRef } from 'react'

export default function Contact() {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_w51wgvb', 'template_bwjb6nr', form.current, 'rAz6tk3TW8wL2OA5-')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
  };
  return (
    <section id='contact'>
     <h5>Get In Touch</h5>
     <h2>Contact Me</h2>

     <div className="container contact_container">
       <div className="contact_options">
         <article className="contact_option">
           <AiOutlineMail className='contact_option-icon'/>
           <h4>Email</h4>
           <h5>noelmiranda19@gmail.com</h5>
           <a href="mailto:noelmiranda19@gmail.com" target='blank'>Send Email</a>
         </article>
         <article className="contact_option">
           <AiFillLinkedin className='contact_option-icon'/>
           <h4>Linkedin</h4>
           {/* <h5>noelmiranda</h5> */}
           <a href="https://www.linkedin.com/in/noel-miranda/" target='blank'>Send Message</a>
         </article>
         <article className="contact_option">
           <AiOutlineWhatsApp className='contact_option-icon'/>
           <h4>WhatsApp</h4>
           <h5>+48-792674311</h5>
           <a href="https://wa.me/+48792674311" target='blank'>Send message</a>
         </article>

       </div>
       {/* END OF CONTACT OPTION */}
       <form ref={form} onSubmit={sendEmail}>
          <input type="text" name="name" placeholder='Your Full Name' required />
          <input type="email" name="email" placeholder='Your Email' required />
          <textarea name="message" rows="7" placeholder='Your Message' required></textarea>
          <button type="submit" className='btn btn-primary'>Send Message</button>
       </form>
     </div>
    </section>
  )
}
