import React from 'react'
import { BsLinkedin, BsGithub } from 'react-icons/bs'
import { AiFillCodepenSquare } from 'react-icons/ai'

export default function HeaderSocial() {
  return (
    <div className='header_social'>
       <a href="https://linkedin.com/in/noel-miranda" target='_blank' rel="noreferrer"><BsLinkedin/></a>
       <a href="https://github.com/noelmiranda?tab=repositories" target='_blank' rel="noreferrer"><BsGithub/></a>
       <a href="https://codepen.io/collection/nVPREV?cursor=ZD0wJm89MCZwPTEmdj00" target='_blank' rel="noreferrer"><AiFillCodepenSquare/></a>
    </div>
  )
}
