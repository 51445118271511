import React from 'react'
import './header.css'
import CTA from './CTA'
import imageAboutMe from "../../assets/portrait1-removebg-preview.png"
import HeaderSocial from './HeaderSocial'
import Video from '../../assets/abstract_-_12902 (Original)'


export default function Header() {
  return (
    <header>
      <div className='video_background'>
      <video autoPlay loop muted src={Video} type="video/mp4" />
      </div>
      <div className="container header_container">
        <h5>Hello I'm</h5>
        <h1>Noel Miranda</h1>
        <h5 className="text-light">Cloud Analyst Application <br /> & <br />Web Developer</h5>
        <CTA />
        <HeaderSocial />

        <div className="me">
          <img src={imageAboutMe} alt="me" />
        </div>
        <a href="#contact" className='scroll_down'>Scroll Down</a>
      </div>
    </header>
  )
}
