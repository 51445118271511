import React from "react";
import "./about.css";
import imageAboutMe from "../../assets/1650746983239.jpg";
import { FaAward } from "react-icons/fa";
import { FaUniversity, FaCity } from "react-icons/fa";

export default function About() {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about_container">
        <div className="about_me">
          <div className="about_me-image">
            <img src={imageAboutMe} alt="" />
          </div>
        </div>
        <div className="about_content">
          <div className="about_cards">
            <article className="about_card">
              <FaAward className="about_icon" />
              <h5>Experience</h5>
              <small>4+ Years Working</small>
            </article>
            <article className="about_card">
              <FaUniversity className="about_icon" />
              <h5>Master in GIS</h5>
              <small>University of Silesia</small>
            </article>
            <article className="about_card">
              <FaCity className="about_icon" />
              <h5>City</h5>
              <small>Gdańsk</small>
            </article>
          </div>

          <p>
            Although my training is in the GIS area. I have merged my
            professional career with web development specifically with MERN
            Stack technology. Using platforms such as Google Developer
            Scholarship, FreeCodeCamp, Pirple, among others. Currently, I'm
            working as a Cloud Application Analyst on the Web as well as Android
            and iOS using Google Firebase products.
          </p>
          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
}
