import CoinGeckoAPI from "../../assets/imgWorks/CoinGeckoAPI.png";
import PhotoGalleryPexels from "../../assets/imgWorks/PhotographyGalleryPexels.png";
import QuoteMachine from "../../assets/imgWorks/radomQuoteMachine.png";
import WikipediaAPI from "../../assets/imgWorks/wikipwdiaAPI.png";
import pomodoroClock from "../../assets/imgWorks/pomodoroClock.png";
import ReactCalculator from "../../assets/imgWorks/ReactCalculator.png";
import DataChoroplethMap from "../../assets/imgWorks/DataChoroplethMap.png";
import D3js from "../../assets/imgWorks/D3js.png";
import MapboxApp from "../../assets/imgWorks/MapBoxApp.png";

export const data = [
  {
    index: 0,
    name: "CoinGeckoAPI",
    title: "CryptoCurrency Price",
    image: `${CoinGeckoAPI}`,
    demo: "https://noelmiranda.github.io/CoinGeckoAPI-React/",
    tools: "HTML * CSS * React * CoinGecko API",
  },
  {
    index: 1,
    name: "PhotographyGalleryPexels",
    title: "Photo Gallery",
    image: `${PhotoGalleryPexels}`,
    demo: "https://noelmiranda.github.io/PhotoGalleryAPI/",
    tools: "HTML * CSS * JavaScript * Pexels API",
  },
  {
    index: 2,
    name: "MapBoxApp",
    title: "Travel Map",
    image: `${MapboxApp}`,
    demo: "https://intense-dawn-82393.herokuapp.com/",
    tools: "HTML * CSS * React * Mapbox API",
  },
  {
    index: 3,
    name: "QuoteMachine",
    title: "Radom Quote Machine",
    image: `${QuoteMachine}`,
    demo: "https://codepen.io/NM19/full/qojQra",
    tools: "HTML * CSS * JavaScript * jQuery",
  },
  {
    index: 4,
    name: "WikipediaAPI",
    title: "Wikipedia API",
    image: `${WikipediaAPI}`,
    demo: "https://codepen.io/NM19/full/MGZgQq",
    tools: "HTML * CSS * JavaScript * jQuery * Wikipedia API",
  },
  {
    index: 5,
    name: "pomodoroClock",
    title: "Pomodoro Clock",
    image: `${pomodoroClock}`,
    demo: "https://codepen.io/NM19/full/bZvwGj",
    tools: "HTML * CSS * JavaScript * React",
  },
  {
    index: 6,
    name: "ReactCalculator",
    title: "Calculator with ReactJS",
    image: `${ReactCalculator}`,
    demo: "https://codepen.io/NM19/full/YBdojz",
    tools: "HTML * CSS * JavaScript * React",
  },
  {
    index: 7,
    name: "DataChoroplethMap",
    title: "Data Choropleth Map",
    image: `${DataChoroplethMap}`,
    demo: "https://codepen.io/NM19/full/Qeaayq",
    tools: "HTML * CSS * JavaScript * D3.js * TopoJSON",
  },
  {
    index: 8,
    name: "D3js",
    title: "D3.js",
    image: `${D3js}`,
    demo: "https://codepen.io/collection/DBJmZe",
    tools: "HTML * CSS * JavaScript * D3.js",
  },
];